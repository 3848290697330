import React from 'react';
import { Helmet } from 'react-helmet';

function YurLicaPage() {
	return (
		<section className="sd:pt-28 xyy:pt-16 pb-10">
			<Helmet>
				<title>{'Ремонт бытовой техники для юридических лиц - безналичный расчет'}</title>
				<meta
					name="description"
					content="Ремонт бытовой техники для юридических лиц: стиральные машины, холодильники, плиты и другое. Безналичный расчет, гарантия качества, выезд мастера. Оставьте заявку прямо сейчас!"
				/>
			</Helmet>

			<div className="container mx-auto px-4">
				<h1 className="text-3xl font-bold text-center mb-6">
					Ремонт бытовой техники для юридических лиц
				</h1>
				<p className="text-lg mb-4">
					Мы предлагаем услуги по ремонту бытовой техники для юридических лиц с возможностью безналичного расчета. Наша компания гарантирует высокий уровень сервиса, оперативность и профессионализм.
				</p>


				<div className="mb-8">
					<h2 className="text-2xl font-semibold mb-3">Порядок выездного ремонта</h2>
					<ol className="list-decimal ml-6 space-y-2">
						<li>ООО "ТРИОЗОН" высылает Заказчику счет на предоплату услуги выезда мастера и диагностики.</li>
						<li>Мастер выезжает после поступления оплаты, проводит диагностику и выполняет ремонт.</li>
						<li>
							Если для ремонта нужны запчасти — оформляется дополнительный счет на оплату запчастей и работ, с учетом предоплаты за диагностику.
						</li>
					</ol>
				</div>

				<div className="mb-8">
					<h2 className="text-2xl font-semibold mb-3">
						Порядок оказания услуг в мастерской
					</h2>
					<ol className="list-decimal ml-6 space-y-2">
						<li>
							Заказчик оформляет гарантийное письмо на фирменном бланке, указывая данные о технике и неисправности.
						</li>
						<li>Согласовывается возможность и дата доставки изделия в мастерскую.</li>
						<li>Заказчик доставляет технику вместе с гарантийным письмом.</li>
						<li>
							Исполнитель принимает технику, оформляет приемную квитанцию и согласовывает стоимость работ.
						</li>
						<li>
							При согласии Заказчика проводится ремонт, в противном случае — только диагностика с оплатой.
						</li>
						<li>
							Заказчик производит оплату по счету и получает технику, предоставив платежные документы и приемную квитанцию.
						</li>
					</ol>
				</div>

				<div className="mb-8">
					<h2 className="text-2xl font-semibold mb-3">
						Образец гарантийного письма
					</h2>
					<p className="mt-9">
						<a href="garant.docx" download="Гарантийное_письмо.docx" className='underline text-blue-600'>
							Во вложении
						</a>{" "}
						образец гарантийного письма для выездного ремонта с оплатой по безналу. Заполните его и вышлите скан для оформления счета на оплату выезда мастера и диагностики.
					</p>
					<p className="text-lg mt-5">
						Для оформления ремонта необходимо гарантийное письмо, которое должно содержать:
					</p>
					<ul className="list-disc ml-6 space-y-2">
						<li>Вид техники, марка, модель, заводской номер</li>
						<li>Характер неисправности</li>
						<li>Банковские реквизиты</li>
						<li>Контактные данные и ФИО ответственного лица</li>
					</ul>
					<p className="text-lg mt-4">
						Отправьте заполненное письмо на <a href="mailto:triozonrem@mail.ru" className="text-blue-600 hover:underline">triozonrem@mail.ru</a>.
					</p>
				</div>

				<div className="text-center">
					<a
						href=""
						className="inline-block bg-blue-600 text-white py-2 px-6 rounded-lg text-lg hover:bg-blue-700 transition"
					>
						Оставить заявку
					</a>
				</div>
			</div>
		</section>
	);
}

export default YurLicaPage;
